export const education = [
  {
    id: 1,
    name: 'Valahia University of Târgoviște',
    description:
      'Bachelor of Economic Sciences, Business Administration, \n Date: 2014 - 2017, \n Grade: Graduated with Honors, \n Bachelor Thesis: Databases and their application in Business (10 ects) (Max Grade)',
    courses: [
      'Microeconomics 6 ects',
      'Applied mathematics in economics 5 ects',
      'Business law 4 ects',
      'Economic informatics 6 ects',
      'Macroeconomics 6 ects',
      'Bases of accounting 6 ects',
      'Data bases 6 ects',
      'Economic statistics 6 ects',
      'European Economy 3 ects',
      'Management 6 ects',
      'Financial accounting 6 ects',
      'Public finances 6 ects',
      'Marketing 6 ects',
      'Communication and public relations in business 5 ects',
      'Human Resources Management 4 ects',
      'Economy in services 5 ects',
      'Econometrics 3 ects',
      'Entreprenurship and Business Administration 5 ects',
      'Practice at a company 3 months internship that resulted in a job 3 ects',
      'Commercial transactions 5 ects',
      'Marketing research 6 ects',
      'Selling techniques 4 ects',
      'Financial economic analysis in services 3 ects',
      'Economy in services 3 ects',
      'International marketing 3 ects',
      'Economic projects and games company 3 ects',
      'Promotional techniques 3 ects',
      'Consummers behaviour 2 ects',
    ],
    cardClass: 'valb-card',
  },
  {
    id: 2,
    name: 'Valahia University of Târgoviște',
    description:
      'Masters Degree in Management, Information Management, \n Date: Oct 2017 - Jul 2019, \n Grade: Graduated with Honors, \n Master Thesis: Databases and their role in the performance of a Public Institution. Case of Study Alexandria Town Hall (10 ects)',
    courses: [
      'Management of organization (8 ects)',
      'Information management (8 ects)',
      'Euro-Atlantic security strategy (7 ects)',
      'Public Relations and Protocol (7 ects)',
      'Change management and risk (6 ects)',
      'Corporate social responsability (6 ects)',
      'Marketing of public services (6 ects)',
      'Knowledge management (6 ects)',
      'Leadership and management of communication (6 ects)',
      'Management of economic diplomacy (6 ects)',
      'Conflict of management and crisis (6 ects)',
      'Public policy in the Euro-Atlantic space (6 ects)',
      'Methodology of economic scientific research (6 ects)',
      'Negotiable corporate governance (Optional course) (6 ects)',
      'National security strategy (5 ects)',
      'International economic Relations (5 ects)',
      'Management of the projects (5 ects)',
      'Practice at a company (5 ects)',
    ],
    cardClass: 'valm-card',
  },
  {
    id: 3,
    name: 'VIA University College',
    description:
      'Software Technology Engineering, Specialization Data Engineering, \n Date: Aug 2021 - Jun 2024 \n Specialization: Data Engineering',
    courses: ['Placeholder1', 'Placeholder2'],
    cardClass: 'viauc-card',
  },
];
